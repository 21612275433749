@import url("https://fonts.googleapis.com/css2?family=Rubik:ital,wght@0,300;0,400;0,500;0,600;0,700;0,800;0,900;1,300;1,400;1,500;1,600;1,700;1,800;1,900&display=swap");
@import "~antd/dist/antd.css";

/* ./styles/globals.css */
@tailwind base;
@tailwind components;
@tailwind utilities;

*,
html,
body {
  font-family: "Rubik";
  scroll-behavior: smooth;
}

#nprogress .bar {
  padding-top: 2px;
  padding-bottom: 2px;
  background: #000 !important;
  border: 1px solid #000;
}

.scrollContainer::-webkit-scrollbar {
  width: 7px;
}

/* Track */
.scrollContainer::-webkit-scrollbar-track {
  background: transparent;
}

/* Handle */
.scrollContainer::-webkit-scrollbar-thumb {
  background: #dadada;
  border-radius: 500px;
}

/* Handle on hover */
.scrollContainer::-webkit-scrollbar-thumb:hover {
  background: #bdbbbb;
}

/* div[aria-live="assertive"] {
  display: none;
} */
